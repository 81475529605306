import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

export default {
  namespaced: true,

  state: {
    answer_dates: [],
    answer_date: {},
  },

  getters: {
    // 回答可能な質問紙のidを渡す
    getAvailableQuestionnaireID(state: any): number {
      function isBetweenStartEnd(val: any): any {
        const start_date = dayjs(val.start_date).subtract(val.questionnaire.days_before, 'd');
        const end_date = dayjs(val.start_date).add(val.questionnaire.days_after, 'd');
        return dayjs().isBetween(start_date, end_date, 'day', '[]');
      }
      const available_questionnaire = state.answer_dates
        .filter((val: any) => val.status == 'not_answered')
        .filter(isBetweenStartEnd);
      if (available_questionnaire.length > 0) {
        return available_questionnaire[0].id;
      } else {
        return 0;
      }
    },
    // 回答可能な質問紙をすべて完了したかを渡す
    getTodayComplete(state: any): boolean {
      function isBetweenStartEnd(val: any): any {
        const start_date = dayjs(val.start_date).subtract(val.questionnaire.days_before, 'd');
        const end_date = dayjs(val.start_date).add(val.questionnaire.days_after, 'd');
        return dayjs().isBetween(start_date, end_date, 'day', '[]');
      }
      const today_questionnaire = state.answer_dates.filter(isBetweenStartEnd);
      const today_complete_questionnaire = state.answer_dates
        .filter((val: any) => val.complete_date !== null)
        .filter(isBetweenStartEnd);
      return today_questionnaire.length > 0 && today_questionnaire.length === today_complete_questionnaire.length;
    },
  },

  mutations: {
    setAnswerDates(state: any, answer_dates: any): void {
      state.answer_dates = answer_dates;
    },
    setAnswerDate(state: any, answer_date: any): void {
      state.answer_date = answer_date;
    },
  },

  actions: {},
};
