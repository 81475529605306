import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import answer from './answer';
import questions from './questions';
import questionnaire from './questionnaire';
import answer_dates from './answer_dates';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],

  state: {
    api_token: null,
    user: null,
    remember: false,
    limit: 0,
    last_view_update: 0,
    showed_e_learnings: [] as Array<{ [key: string]: string }>,
  },

  getters: {
    apiToken: (state): string | null => state.api_token,
    user: (state): object | null => state.user,
    remember: (state): boolean => state.remember,
    limit: (state): number => state.limit,
    lastViewUpdate: (state): number => state.last_view_update,
    showELearning: (state): boolean => {
      if (state.user == null) {
        return false;
      }
      const user = state.user as any;
      let result = true;
      console.log(user);
      state.showed_e_learnings.forEach(showedELearning => {
        if (showedELearning['userId'] == user.id && showedELearning['projectId'] == user.project_id) {
          result = false;
        }
      });

      return result;
    },
  },

  mutations: {
    setApiToken(state, api_token): void {
      state.api_token = api_token;
    },

    setUser(state, user): void {
      state.user = user;
    },

    setRemember(state, remember): void {
      state.remember = remember;
    },

    setLimit(state, limit): void {
      state.limit = limit;
    },

    setLastViewUpdate(state, last_view_update): void {
      state.last_view_update = last_view_update;
    },

    addShowedELearning(state): void {
      if (state.user == null) {
        return;
      }
      const user = state.user as any;
      let result = true;
      state.showed_e_learnings.forEach(showedELearning => {
        if (showedELearning['userId'] == user.id && showedELearning['projectId'] == user.project_id) {
          result = false;
        }
      });

      if (result) {
        state.showed_e_learnings.push({ userId: user.id, projectId: user.project_id });
      }
    },
  },

  actions: {},

  modules: {
    answer: answer,
    questions: questions,
    questionnaire: questionnaire,
    answer_dates: answer_dates,
  },
});
