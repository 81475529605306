import 'core-js/stable';
import Vue, { VNode } from 'vue';
// メイン
import App from './App.vue';
// WEBページとは別で動作するライフサイクル
import './registerServiceWorker';
// ルーター
import router from './router';
// store
import store from './store';
// // bootstrap
// import 'bootstrap/dist/css/bootstrap.min.css';
// core ui module
import CoreuiVue from '@coreui/vue';
// icon
import { iconsSet as icons } from './assets/icons/icons';
// datepicker
import Datepicker from 'vuejs-datepicker';
// toast
import Toasted from 'vue-toasted';
// ローディング
import VueLoading, { VueLoadingOptions } from 'vue-loading-template';

Vue.config.performance = true;
Vue.config.productionTip = false;
Vue.use(CoreuiVue);
Vue.prototype.$log = console.log.bind(console);
Vue.component('Datepicker', Datepicker);
Vue.use(Toasted);
Vue.use<VueLoadingOptions>(VueLoading, { type: 'spiningDubbles' });

new Vue({
  router,
  store,
  icons,
  render: (h): VNode => h(App),
}).$mount('#app');
