import Vue from 'vue';
import store from '../store';
import dayjs from 'dayjs';
import VueRouter, { RouteConfig } from 'vue-router';

const TheAuthContainer = (): Promise<typeof import('@/containers/TheAuthContainer.vue')> =>
  import('@/containers/TheAuthContainer.vue');
const Login = (): Promise<typeof import('@/views/auth/Login.vue')> => import('@/views/auth/Login.vue');
const ForgetPassword = (): Promise<typeof import('@/views/auth/ForgetPassword.vue')> =>
  import('@/views/auth/ForgetPassword.vue');
const ResetPassword = (): Promise<typeof import('@/views/auth/ResetPassword.vue')> =>
  import('@/views/auth/ResetPassword.vue');
const TheContainer = (): Promise<typeof import('@/containers/TheContainer.vue')> =>
  import('@/containers/TheContainer.vue');
const Index = (): Promise<typeof import('@/views/Index.vue')> => import('@/views/Index.vue');
const QuestionStart = (): Promise<typeof import('@/views/QuestionStart.vue')> => import('@/views/QuestionStart.vue');
const QuestionStartPreview = (): Promise<typeof import('@/views/QuestionStartPreview.vue')> =>
  import('@/views/QuestionStartPreview.vue');
const QuestionItem = (): Promise<typeof import('@/views/QuestionItem.vue')> => import('@/views/QuestionItem.vue');
const QuestionItemByGroup = (): Promise<typeof import('@/views/QuestionItemByGroup.vue')> =>
  import('@/views/QuestionItemByGroup.vue');
const QuestionConfirm = (): Promise<typeof import('@/views/QuestionConfirm.vue')> =>
  import('@/views/QuestionConfirm.vue');
const QuestionComplete = (): Promise<typeof import('@/views/QuestionComplete.vue')> =>
  import('@/views/QuestionComplete.vue');
const QuestionCompletePreview = (): Promise<typeof import('@/views/QuestionCompletePreview.vue')> =>
  import('@/views/QuestionCompletePreview.vue');
const PasswordSetting = (): Promise<typeof import('@/views/auth/PasswordSetting.vue')> =>
  import('@/views/auth/PasswordSetting.vue');

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/auth',
    component: TheAuthContainer,
    children: [
      // ログイン画面
      {
        path: 'login',
        name: 'Login',
        component: Login,
      },
      {
        path: 'password/send-email',
        name: 'ForgetPassword',
        component: ForgetPassword,
      },
      {
        path: 'password/reset',
        name: 'ResetPassword',
        component: ResetPassword,
      },
    ],
  },
  {
    // プレビュー用
    path: '/preview',
    component: TheContainer,
    children: [
      // 各proの回答開始画面
      {
        path: ':uuid/start',
        name: 'QuestionStart',
        component: QuestionStartPreview,
      },
      // 各質問項目（質問グループ、質問のnoは数え番号でidや配列のindexではない）
      {
        path: 'questionnaire/:uuid/:question_group_no/:question_no',
        name: 'QuestionItem',
        component: QuestionItem,
      },
      // 質問群の全質問項目（質問グループ、質問のnoは数え番号でidや配列のindexではない）
      {
        path: 'questionnaire-by-group/:uuid/:question_group_no',
        name: 'QuestionItemByGroup',
        component: QuestionItemByGroup,
      },
      // proごとの回答確認画面
      {
        path: 'questionnaire/:uuid/confirm',
        name: 'QuestionConfirm',
        component: QuestionConfirm,
      },
      // proごとの回答完了画面
      {
        path: 'questionnaire/:uuid/complete',
        name: 'QuestionComplete',
        component: QuestionCompletePreview,
      },
    ],
  },
  {
    path: '/',
    component: TheContainer,
    children: [
      // 日毎の質問紙がボタンで選択できる
      {
        path: '/index',
        name: 'Index',
        component: Index,
        meta: { requiresAuth: true },
      },
      // 各proの回答開始画面
      {
        path: '/answer_date/:answer_date_id/start',
        name: 'QuestionStart',
        component: QuestionStart,
        meta: { requiresAuth: true },
      },
      // 各質問項目（質問グループ、質問のnoは数え番号でidや配列のindexではない）
      {
        path: '/questionnaire/:questionnaire_id/:question_group_no/:question_no',
        name: 'QuestionItem',
        component: QuestionItem,
        meta: { requiresAuth: true },
      },
      // 質問群の全質問項目（質問グループ、質問のnoは数え番号でidや配列のindexではない）
      {
        path: '/questionnaire-by-group/:questionnaire_id/:question_group_no',
        name: 'QuestionItemByGroup',
        component: QuestionItemByGroup,
        meta: { requiresAuth: true },
      },
      // proごとの回答確認画面
      {
        path: '/questionnaire/:questionnaire_id/confirm',
        name: 'QuestionConfirm',
        component: QuestionConfirm,
        meta: { requiresAuth: true },
      },
      // proごとの回答完了画面
      {
        path: '/questionnaire/:questionnaire_id/complete',
        name: 'QuestionComplete',
        component: QuestionComplete,
        meta: { requiresAuth: true },
      },
      {
        path: '/password/setting',
        name: 'PasswordSetting',
        component: PasswordSetting,
        meta: { requiresAuth: true },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  scrollBehavior: (): { x: number; y: number } => ({ x: 0, y: 0 }),
  routes,
});

// 認証を入れる
router.beforeEach((to, from, next) => {
  if (
    // 認証必要ページでtokenがなければログイン画面にリダイレクト
    to.matched.some(record => record.meta.requiresAuth) &&
    store.state.api_token == null
  ) {
    next({ path: '/auth/login' });
  } else if (
    // 認証必要ページでtokenがあっても期限切れならログイン画面にリダイレクト
    to.matched.some(record => record.meta.requiresAuth) &&
    store.state.api_token != null &&
    !store.state.remember &&
    dayjs().unix() > store.state.limit
  ) {
    store.commit('setUser', null);
    store.commit('setRemember', false);
    store.commit('setLimit', 0);
    store.commit('setApiToken', null);
    store.commit('answer/setAnswerDate', '');
    store.commit('answer/setAnswers', {});
    store.commit('answer/setSubjectNumber', '');
    next({ path: '/auth/login' });
  } else if (
    // 認証必要ページでtokenが有効なら，期限を伸ばす
    to.matched.some(record => record.meta.requiresAuth) &&
    store.state.api_token != null &&
    !store.state.remember
  ) {
    const limit = dayjs()
      .add(1, 'days')
      .unix();
    store.commit('setLimit', limit);
    next();
  } else {
    // その他の場合はそのまま表示
    next();
  }
});

export default router;
