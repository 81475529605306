export default {
  namespaced: true,

  state: {
    questions: [],
  },

  mutations: {
    setQuestions(state: any, questions: any): void {
      state.questions = questions;
    },
  },

  actions: {},
};
