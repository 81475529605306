
import Vue from 'vue';
import Component from 'vue-class-component';
import Loading from './components/Loading.vue';

@Component({
  components: {
    Loading,
  },
})
export default class App extends Vue {
  // ローディング表示
  is_loading = true;

  // Loadingの表示，非表示
  toggleLoading(params: { is_loading: boolean; time: number }): void {
    if (params.is_loading) {
      this.is_loading = true;
    } else if (params.time != 0) {
      setTimeout(() => {
        this.is_loading = false;
      }, params.time * 1000);
    } else {
      this.is_loading = false;
    }
  }
}
