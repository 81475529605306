export default {
  namespaced: true,

  state: {
    answers: {},
    answer_date: '',
    subject_number: '',
  },

  mutations: {
    setAnswers(state: any, answers: any): void {
      state.answers = answers;
    },

    setAnswerDate(state: any, answer_date: any): void {
      state.answer_date = answer_date;
    },

    setSubjectNumber(state: any, subject_number: any): void {
      state.subject_number = subject_number;
    },
  },

  actions: {},
};
