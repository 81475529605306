/**
 * display_order の順に質問群・質問を並び替える
 * @param question_groups 質問群
 * @returns 並び替えられたquestion_groups
 */
function sortQuestionGroups(question_groups: any[]): any {
  // 質問群の中の質問をまずソート
  question_groups.forEach(question_group => {
    question_group.questions.sort((a: any, b: any) => a.display_order - b.display_order);
  });
  // 続けて質問群をソート
  question_groups.sort((a: any, b: any) => a.display_order - b.display_order);
  return question_groups;
}

export default {
  namespaced: true,

  state: {
    questionnaire: [],
  },

  mutations: {
    setQuestionnaire(state: any, questionnaire: any): void {
      console.log('setQuestionnaire');
      console.log(questionnaire);
      questionnaire.question_groups = sortQuestionGroups(questionnaire.question_groups);
      state.questionnaire = questionnaire;
    },
  },

  actions: {},
};
